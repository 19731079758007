(function ($, window, document, undefined) {

  'use strict';

  $(function () {
    // FastShell
  });

})(jQuery, window, document);

// var acc = document.getElementsByClassName("c-serviceItemBoxAccordion__btn");
// var i;

// for (i = 0; i < acc.length; i++) {
//   acc[i].addEventListener("click", function() {
//     var panel = $(".c-serviceItemBoxAccordion__panel");
//     console.log(panel.css("max-height"));

//       // panel.style.maxHeight = panel.scrollHeight + "px";
//       panel.css("max-height", panel.prop('scrollHeight'));
//       console.log(panel.css("max-height"));
//   });
// }


var acc = document.getElementsByClassName("c-serviceItemBoxAccordion__btn");
var i;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function() {
    this.classList.toggle("active");
    var panel = this.nextElementSibling;
    if (panel.style.maxHeight){
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    } 
  });
}